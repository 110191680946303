<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:status="{ row }">
          <span>{{ reversalStatus(row) }}</span>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
          <vxe-button type="text" status="primary" @click="goPage('add')">添加</vxe-button>
        </template>
      </vxe-grid>
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import api from '@/store/API/api'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      homeServRecdStatusLst: [],
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center',
        },
        {
          field: 'serviceItem',
          title: '服务项目',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'status',
          title: '订单状态',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
          slots: {
            default: 'status',
          },
        },
        {
          field: 'satisfaction',
          title: '满意度',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'address',
          title: '服务区域（地址）',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          title: '操作',
          width: 200,
          showOverflow: true,
          slots: {
            default: 'operation',
          },
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    ...mapActions(['getServiceRecordList', 'deleteServiceRecord', 'getSysDictServe']),
    initView() {
      this.getLists()
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddServiceRecord',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id,
          serviceOrgId: this.$route.params.id,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
          source: this.$route.query.type
        },
      })
    },
    getLists() {
      this.loading = true
      this.getServiceRecordList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          workSiteId: this.$route.params.id,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.deleteServiceRecord({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
    getDicList() {
      this.getSysDictList('homeServRecdStatus', 'homeServRecdStatusLst')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    reversalStatus(row) {
      let msg = row.status
      for (let i = 0; i < this.homeServRecdStatusLst.length; i++) {
        if (row.status === this.homeServRecdStatusLst[i].dictPidVal) {
          msg = this.homeServRecdStatusLst[i].dictDesc
        }
      }
      return msg
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
